import { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { camelCase, kebabCase } from 'lodash';
import scrollama from 'scrollama';

import Layout from 'layouts/default';

import ACFImage from 'types/ACFImage';

import Button from 'components/buttons/button';
import Yoast from 'components/globals/yoast';
import { HeroDefaultSignup } from 'components/hero/default-signup';
import Link from 'components/link/link';
import PartnersSlider from 'components/sliders/partners';

import './styles/integrations.scss';

type Category = [
  {
    name: string;
    partner: [
      {
        excerpt: string;
        logo: ACFImage;
        name: string;
        premier_partner: boolean;
        url: string;
        when_i_work_certified: boolean;
      }
    ];
  }
];

export default function Integrations() {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "integrations" } }) {
        edges {
          node {
            wordpress_id
        yoast_head_json {
          title
          description
          canonical
          og_title
          og_description
          og_image {
            url {
              source_url
            }
          }
          og_url
          og_type
          twitter_title
          twitter_description
          twitter_image {
            source_url
          }
        }
            acf {
              hero {
                title
                sub_title
                signup {
                  launch_modal_button_text
                  modal_submit_button_text
                }
                background_desktop_image {
                  source_url
                }
                background_mobile_image {
                  source_url
                }
              }
              basic_title_subtitle_copy_image {
                copy
                title
                button_url
                button_text
              }
            }
          }
        }
      }
      allWordpressAcfOptions {
        edges {
          node {
            options {
              integrations {
                categories {
                  name
                  partner {
                    when_i_work_certified
                    url
                    premier_partner
                    name
                    excerpt
                    logo {
                      source_url
                    }
                  }
                }
                premium {
                  title
                  partner {
                    excerpt
                    logo {
                      source_url
                    }
                    name
                    premier_partner
                    url
                    when_i_work_certified
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const pageContext = data.allWordpressPage.edges[0].node.acf;
  const hero = pageContext.hero;
  const yoast = data.allWordpressPage.edges[0].node.yoast_head_json;
  const categories = Object.values(data.allWordpressAcfOptions.edges[0].node.options.integrations.categories) as Category;
  const premium = data.allWordpressAcfOptions.edges[0].node.options.integrations.premium;
  const contactForm = pageContext.basic_title_subtitle_copy_image;

  const breakpoints = useBreakpoint();

  const [stepVisible, setStepVisible] = useState(null);

  useEffect(() => {
    const scroller = scrollama();

    // scrollama event handlers
    function handleStepEnter(response) {
      // Update use state with section index number
      setStepVisible(response.index);
    }

    // setup the instance, pass callback functions
    !breakpoints.sm &&
      scroller
        .setup({
          step: '.step',
          offset: 0.1,
          threshold: 1,
        })
        .onStepEnter(handleStepEnter);

    // setup resize event
    window.addEventListener('resize', scroller.resize);
  }, []);

  return (
    <Layout signupModalSubmit={ hero.signup.modal_submit_button_text }>
      <Yoast { ...yoast } />
      <div className="integrations-page" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <HeroDefaultSignup { ...hero } />
        <section className="premium-section section">
          <div className="container">
            <h2 className="headline has-text-centered">{premium.title}</h2>
            <PartnersSlider { ...premium.partner } />
          </div>
        </section>
        <section className="full-list-section section">
          <div className="container is-max-desktop">
            <nav
              className="partners-navigation"
              data-sticky-container
              data-section="integrations partners navigation"
            >
              <ul data-margin-top="100">
                {categories.map((category, i) => (
                  <li
                    key={ `category-${i + 1}` }
                    className={ stepVisible === i ? 'is-active' : '' }
                  >
                    <Link to={ `#${camelCase(category.name)}` }>
                      {category.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
            <section className="partners-list">
              {categories.map((category, i) => (
                <div
                  key={ `category-${i + 1 + 2}` }
                  className={ `partners-category step ${kebabCase(
                    category.name
                  )}` }
                  data-step={ i }
                >
                  <h2 className="headline" id={ camelCase(category.name) }>
                    {category.name}
                  </h2>
                  <PartnersSlider
                    className="is-multiline"
                    { ...category.partner }
                  />
                </div>
              ))}
            </section>
          </div>
        </section>
        <section className="partners-contact-form-section section">
          <div className="container">
            <h2 className="title has-text-center">{contactForm.title}</h2>
            <p className="copy">{contactForm.copy}</p>
            <Button
              type="link"
              className="button is-green is-rounded"
              href={ contactForm.button_url }
              text={ contactForm.button_text }
            />
          </div>
        </section>
      </div>
    </Layout>
  );
}
