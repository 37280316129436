import { useState, Fragment } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import Link from 'components/link/link';

export default function PartnersSlider({
  className,
  ...props
}) {
  const [itemIndex, setItemIndex] = useState(0); // default plan
  const breakpoints = useBreakpoint();

  function DesktopMarkup() {
    return Object.values(props).map((partner, index) => (
      <div
        key={ index }
        className={ `column ${
          Boolean(partner.premier_partner) ? 'green-star' : ''
        }` }
        data-section={ partner.name.toLowerCase() }
      >
        <div className="card has-shadow-large">
          <div
            className={ `card-image ${
              Boolean(partner.when_i_work_certified) ? 'certified' : ''
            }` }
          >
            <figure className="image">
              <img
                loading="lazy"
                className="card-img"
                src={ partner.logo.source_url }
                alt="fill me out"
              />
            </figure>
          </div>
          <div className="card-content">
            <h3 className="card-header-title has-padding-bottom-md">
              {partner.name}
            </h3>
            <p className="content">{partner.excerpt}</p>
            {partner.url !== '' ? (
              <Link className="content-cta" to={ partner.url } target="_blank">
                Try it now
              </Link>
            ) : (
              <p className="content-cta">Coming Soon</p>
            )}
          </div>
        </div>
      </div>
    ));
  }

  function MobileMarkup() {
    return (
      <SwipeableViews
        onChangeIndex={ i => setItemIndex(i) }
        className="products-swipeable-root"
        style={ {
          overflowX: 'visible',
          padding: '0px calc(42% - 128px)',
        } }
        slideClassName="card-slide"
        springConfig={ {
          easeFunction: 'cubic-bezier(0, 0, 0.24, 0.96)',
          duration: '.8s',
          delay: '0s',
        } }
        index={ itemIndex }
        enableMouseEvents
      >
        {Object.values(props).map((partner, index) => (
          <div
            key={ index }
            className={ `column ${
              Boolean(partner.premier_partner) ? 'green-star' : ''
            }` }
            data-section={ partner.name.toLowerCase() }
          >
            <div className="card has-shadow-large">
              <div
                className={ `card-image ${
                  Boolean(partner.when_i_work_certified) ? 'certified' : ''
                }` }
              >
                <figure className="image">
                  <img
                    loading="lazy"
                    className="card-img"
                    src={ partner.logo.source_url }
                    alt="fill me out"
                  />
                </figure>
              </div>
              <div className="card-content">
                <h3 className="card-header-title has-padding-bottom-md">
                  {partner.name}
                </h3>
                <p className="content">{partner.excerpt}</p>
                {partner.url !== '' ? (
                  <Link
                    className="content-cta"
                    target="_blank"
                    to={ partner.url }
                  >
                    Try it now
                  </Link>
                ) : (
                  <p className="content-cta">Coming Soon</p>
                )}
              </div>
            </div>
          </div>
        ))}
      </SwipeableViews>
    );
  }

  function Dots() {
    return (
      <div className="slider-dots">
        {Object.keys(props).map(i => (
          <div
            key={ i }
            className={ `slider-dot ${parseInt(i) === itemIndex &&
              'slider-dot-filled'}` }
          />
        ))}
      </div>
    );
  }

  return (
    <div
      className={ `partners-slider columns is-swipeable ${className || ''}` }
    >
      {!breakpoints.sm ? (
        <DesktopMarkup />
      ) : (
        <Fragment>
          <MobileMarkup />
          <Dots />
        </Fragment>
      )}
    </div>
  );
}
